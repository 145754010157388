import { observable, action } from 'mobx';
import _sc from '../constants/__survey';

import {
  ISurvey,
  ISurveyServices,
  ISurveyStore,
  ISaveSurveyObject,
  ILocation,
  IgetUserSurveysQuery,
} from '../../interfaces/ISurvey';

import SurveyService from '../services/SurveyService';
import __envs from '../constants/__envs';

class SurveyModel implements ISurveyStore {
  @observable slug = '';
  @observable countryCode = '';
  @observable userId = '';
  @observable productId = '';
  @observable uuid: string | null = null;
  @observable title = _sc.defaultValues.title;
  @observable status = _sc.appStatus.loading;
  @observable surveyAnswerView = _sc.defaultValues.answerView;
  @observable extraErrors = {};
  @observable formData = {};
  @observable survey: ISurvey = _sc.defaultValues.survey;
  @observable extraData = {};
  @observable surveyAnswerStored: any = null;
  @observable loadingButton = false;
  surveyServices: ISurveyServices;

  constructor(services: ISurveyServices) {
    this.surveyServices = services;
  }

  @action
  getSurvey = async (slug: string = '', countryCode: string = '', userId: string = 'anonymous', companyProcessId: string = '') => {
    this.setUserId(userId);
    const survey = await this.surveyServices.getSurvey({
      slug, countryCode
    });
    //If there is a survey
    if (survey && survey.id) {

      this.slug = survey.slug
      this.countryCode = survey.countryCode;
      if(survey.productId){
        this.productId = survey.productId;
      }

      this.survey = {
        id: survey.id,
        jsonSchema: survey.jsonSchema,
        uiSchema: survey.uiSchema,
        extraErrors: survey.extraErrors ? survey.extraErrors : {},
        formData: survey.formData ? survey.formData : {}
      }

      this.title = survey.title;
      this.surveyAnswerView = survey.answerSchema;

      const productId = this.productId;
      const surveyId = survey.id;
      await this.getUserSurveyAnswers({slug, countryCode, userId, productId});
      if(companyProcessId !== '' && companyProcessId !== 'non_set') {
        await this.surveyServices.associateSurvey({ crypto: companyProcessId, surveyId });
      }
    } else {
      this.setDefaults();
    }
  }

  @action
  getUserSurveyAnswers = async (queryStringsParams: IgetUserSurveysQuery) => {
    this.setStatus(_sc.appStatus.loading);

    this.getUUID();
    const result = await this.surveyServices.getUserSurveyAnswers({ uuid: this.uuid, ...queryStringsParams });
    //If no surveys found 404, create a new empty answer.
    if (result.response?.status === 404) {
      await this.saveEmptySurveyAnswer();
      return true;
    }
    //If something went wrong, set "no surveys available" view.
    if (result.response?.status === 400) {
      this.setDefaults();
      return false;
    }
    //If user is anonymous or uuid or email not valid or not present, set normal form.
    if (result.response?.status === 401) {
      this.setStatus(_sc.appStatus.surveyIsLoaded);
      return false;
    }

    if (result && result.id) {
      this.setFormData(result.answer);
      this.setSurveyAnswerStored(result);
      this.setStatus(_sc.appStatus.surveyIsLoaded);
    } else {
      this.setDefaults();
      return false;
    }
  }

  @action
  saveEmptySurveyAnswer = async () => {
    const surveyAnswerObject = this.setSurveyAnswerObject({}, 0);
    const createdSurveyResponse = await this.surveyServices.saveSurveyAnswer(surveyAnswerObject);

    if (createdSurveyResponse && createdSurveyResponse.id) {
      this.setStatus(_sc.appStatus.surveyIsLoaded);
      this.setFormData(createdSurveyResponse.answer);
      this.setSurveyAnswerStored(createdSurveyResponse);
      this.setExpirationUUID();
    }
  }

  @action
  saveSurveyAnswer = async (formData: object, location: ILocation) => {
    this.status = _sc.appStatus.loading;

    const saveSurveyObject = this.setSurveyAnswerObject(formData, 1);
    const surveySavedResponse = await this.surveyServices.saveSurveyAnswer(saveSurveyObject);

    if (surveySavedResponse && surveySavedResponse.id) {
      this.setSuccess(location);
    } else {
      this.setError(location)
    }
  }

  @action
  updateSurveyAnswer = async (formData: any, location: ILocation, isCompleted = 0) => {
    this.getUUID();
    const updateSurveyAnswerObject = this.setSurveyAnswerObject(formData, isCompleted)
    const queryParams = { surveyAnswerId: this.surveyAnswerStored.id, uuid: this.uuid, slug: this.slug }
    const updatedSurvey = await this.surveyServices.updateSurveyAnswer(queryParams, updateSurveyAnswerObject);

    if (!updatedSurvey) {
      this.setError(location);
      this.loadingButton = false;
      return false;
    }
    //Updated survey but not completed yet.
    if (updatedSurvey && isCompleted === 0) {
      this.setSurveyAnswerStored(updatedSurvey);
      this.loadingButton = false;
      return true;
    }
    //Updated survey and completed.
    if (updatedSurvey && updatedSurvey.id && isCompleted === 1) {
      this.loadingButton = false;
      this.setSuccess(location);
      return true;
    }
  }

  @action
  setSurveyAnswerStored = (surveyAnswer: any) => {
    localStorage.setItem(__envs.StorageUUIDName, surveyAnswer.uuid);
    this.getUUID();
    this.surveyAnswerStored = surveyAnswer;
  }

  @action
  setSurveyAnswerObject = (formData: object, isCompleted = 0) => {

    const surveyAnswerObject: ISaveSurveyObject = {
      formData: {
        ...formData,
        ...this.extraData
      },
      surveyId: this.survey.id,
      userId: this.userId,
      isCompleted: isCompleted,
      metadata: {
        userId: this.userId,
        productId: this.productId,
        source: 'survey-webapp',
        countryCode: this.countryCode
      }
    };
    return surveyAnswerObject;
  }

  @action
  setStatus = (newStatus: string) => {
    this.status = newStatus;

  }

  @action
  setUserId = (newUserId: string) => {
    this.userId = newUserId
  }

  @action
  getUUID = () => {
    const expirationDate = new Date(localStorage.getItem(__envs.StorageUUIDExpirationName) || '');
    const now = new Date();
    if (expirationDate && now >= expirationDate) {
      this.deleteUUID();
    } else {
      this.uuid = localStorage.getItem(__envs.StorageUUIDName)
    }
  }

  @action
  deleteUUID = () => {
    localStorage.removeItem(__envs.StorageUUIDName);
    localStorage.removeItem(__envs.StorageUUIDExpirationName);
    this.uuid = null;
  }

  @action
  setExpirationUUID = () => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + __envs.uuidExpirationDays);
    localStorage.setItem(__envs.StorageUUIDExpirationName, expirationDate.toString())
  }

  @action
  setProductId = (newProductId: string) => {
    if(this.productId === '' || this.productId === 'non_set'){
      this.productId = newProductId
    }
  }

  @action
  setDefaults = () => {
    this.title = _sc.defaultValues.title;
    this.status = _sc.appStatus.initial;
    this.surveyAnswerView = _sc.defaultValues.defaults;
  }

  @action
  setError = (location: ILocation) => {
    this.title = _sc.defaultValues.title;
    this.status = _sc.appStatus.initial;
    this.surveyAnswerView = _sc.defaultValues.surveyErrorView;
    this.surveyAnswerView.link = `${location.pathname + location.search}`;
  }

  @action
  setSuccess = (location: ILocation) => {
    this.deleteUUID();
    this.status = _sc.appStatus.surveyAnswerIsSaved;
    this.title = this.surveyAnswerView.app_title;
  }

  @action
  setFormData = (formData: any) => {
    this.survey.formData = formData
  }

  @action
  setExtraData = (extraData: any) => {
    this.extraData = {
      ...this.extraData,
      ...extraData
    }
  }

  @action
  getExtraData = () => {
    return this.extraData;
  }

  @action
  getFormData = () => {
    return this.formData;
  }

}


export default new SurveyModel(SurveyService);
