import React, { useState } from 'react';
import { FieldProps } from 'react-jsonschema-form';
import { Fragment } from 'react';
// import SurveyStore from '../../store/models/SurveyModel';
import ReactStars from '../../components/reusable/RatingStars/react-stars';
/**
 * To use correctly this helper, you should have deffined a property called "Otro" in your Enum field.
 */

function StarRatingOptionEnumFieldComponent(props: FieldProps) {
  // const values
  const [appliedTarget, setAppliedTarget] = useState(props.formData===0);
  const [resetStarsInitialValue, setResetStarsInitialValue] = useState(props.formData===0);
  const [formData, setFormData ] = useState(props.formData);

  // @ts-ignore
  const enumOptions: any = props.schema.enum;

  const ratingChanged = (newRating: any) => {
    setAppliedTarget(false);
    setFormData(newRating);
    props.onChange(newRating);
  }

  const appliedTargetChanged = () => {
    setFormData(0);
    setAppliedTarget(true);
    resetStarsInitialValue ? setResetStarsInitialValue(false) : setResetStarsInitialValue(true);
    props.onChange(0);
  }
  
  return (
    <Fragment>
      <label className="control-label" htmlFor={props.idSchema.$id}>
        {props.schema.title}{ props.required ? '*' : null }
      </label>
      { enumOptions.length > 5 ?
        <Fragment>
          <div>
            <div className={'star-satisfaction-wrapper center-stars-box'} id={props.idSchema.$id}>
              <ReactStars
                classNames={'star-satisfaction'}
                count={5}
                onChange={ratingChanged}
                size={45}
                activeColor={'#ffd700'}
                color={'#8080804d'}
                resetStars={resetStarsInitialValue}
                value={formData}
              />
            </div>
          </div>
          <div className="form-group field field-boolean" style={{marginBottom: '0px'}}>
            <div className="radio ">
              <label className={'radio-inline'} style={{ marginBottom: '0px', marginLeft: '5px'}}>
                <input type="radio" id="root_active" onChange={appliedTargetChanged} checked={appliedTarget} style={{marginRight: '5px'}}/>
                <span>No requerí soporte</span>
              </label>
            </div>
          </div> 
        </Fragment> :
        <div className={'star-satisfaction-wrapper center-stars-box'} id={props.idSchema.$id}>
          <ReactStars
            classNames={'star-satisfaction'}
            count={5}
            onChange={ratingChanged}
            size={45}
            activeColor={'#ffd700'}
            color={'#8080804d'}
            value={formData}
          />
        </div>
      }
    </Fragment>
  );
}

export default StarRatingOptionEnumFieldComponent;