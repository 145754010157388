import * as React from 'react';

export interface Props {
	url: string,
	text: string
}

export default function(props:Props)  {
		return (
			<div className="cmp-redirect-element ">
				<a href={props.url} className='light-blue-button btn font-raleway font-bold'>
					{props.text}
				</a>
			</div>
		)
}
