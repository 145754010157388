import React, { useEffect, useCallback} from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { getQueryParams } from '../../../helpers/survey/survey';

import SurveyStore from '../../../store/models/SurveyModel';
import { observer } from 'mobx-react';

import Form from "react-jsonschema-form";
import Loading from "../../reusable/Loading/Loading";
import DefaultView from "../../reusable/DefaultView/DefaultView";
import RandonOptionEnumFieldComponent from "../../../helpers/rjsfSortRandomEnumValues/SortRandomEnumValues";
import SortRandomEnumFieldRadioButton from "../../../helpers/rjsfSortRandomEnumValues/SortRandomEnumValuesRadioButton";
import StarRatingOptionEnumFieldComponent from "../../../helpers/rjsfSortRandomEnumValues/StarRatingEnumValues";

import _sc from '../../../store/constants/__survey';

import ReactJsonSchemaHelper from '../../../helpers/app/jschema_form';

import __envs from '../../../store/constants/__envs';
import _ from 'lodash';

export default observer(function (props: any) {
  const params: any = useParams();
  const { slug, countryCode } = params;
  const location = useLocation();
  const history = useHistory();
  let customFields = {
    SortRandomField: RandonOptionEnumFieldComponent,
    SortRandomFieldRadioButton: SortRandomEnumFieldRadioButton,
    StarRatingField: StarRatingOptionEnumFieldComponent
  };
  let queryStringsParams = getQueryParams(location.search);

  // cdm - get the data, set the userId
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    queryStringsParams = getQueryParams(location.search);
    initSurvey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, countryCode, queryStringsParams.userId, queryStringsParams.productId]);


  const initSurvey = async () => {
    const { userId, productId, companyProcessId } = queryStringsParams;
    SurveyStore.setProductId(productId);
    await SurveyStore.getSurvey(slug, countryCode, userId, companyProcessId);
  }


  const onChange = (event: any) => {
    const { formData } = event;
    const { userId } = queryStringsParams;
    SurveyStore.setFormData(formData);
    if (userId !== 'anonymous' && userId?.toString().match(__envs.emailRegex)) {
      SurveyStore.loadingButton = true;
      debounce(formData);
    }
  }
  const debounce = useCallback(
    _.debounce((formData) => {
      SurveyStore.updateSurveyAnswer(formData, location, 0);
    }, __envs.debounceTimeApiRequest),
    []
  );

  async function submitSurvey(data: any) {
    //Check if there is a surveyAnswerStored
    if (SurveyStore.surveyAnswerStored) {
      //Update the stored surveyAnswer as completed.
      SurveyStore.updateSurveyAnswer(data.formData, location, 1);
    } else {
      //Saving as usual.
      SurveyStore.saveSurveyAnswer(data.formData, location);
    }
    history.push('/');
  }

  return (
    <div>
      {
        SurveyStore.status === _sc.appStatus.surveyIsLoaded &&
        <Form
          schema={SurveyStore.survey.jsonSchema}
          uiSchema={SurveyStore.survey.uiSchema}
          showErrorList={false}
          formData={SurveyStore.survey.formData ? SurveyStore.survey.formData : {}}
          onSubmit={submitSurvey}
          transformErrors={ReactJsonSchemaHelper.transformErrorsAdapter(SurveyStore.survey.extraErrors)}
          fields={customFields}
          onChange={onChange}
        >
          <button type="submit" className="btn btn-info" disabled={SurveyStore.loadingButton}>ENVIAR</button>
        </Form>
      }
      {
        SurveyStore.status === _sc.appStatus.loading &&
        <Loading />
      }
      {
        SurveyStore.status === _sc.appStatus.initial &&
        <DefaultView />
      }
    </div>
  )

});
