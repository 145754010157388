import React from 'react';

import SurveyStore from '../../../store/models/SurveyModel';
import {observer} from 'mobx-react';

import {ISurveyAnswer} from '../../../interfaces/ISurvey';

import ButtonLink from '../../reusable/ButtonLink/ButtonLink';

export default observer(function () {
  const answerView:ISurveyAnswer = SurveyStore.surveyAnswerView;
  return (
      <div>
        {
          <div className="cmp-survey-done fade-in">
            <h2>
              {answerView.title}
            </h2>
            <div className="subtitle" dangerouslySetInnerHTML={{__html:answerView.subtitle}}>

            </div>
            <div className="emoticon success">:-)</div>
            <div className="cta-container">
              <ButtonLink url={answerView.link} text={answerView.linkText}/>
            </div>
          </div>
        }
      </div>
  )

});
