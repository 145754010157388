import {IQueryStringsParams} from '../../interfaces/ISurvey';

export function getQueryParams(queryString: string): IQueryStringsParams {
  const query = new URLSearchParams(queryString);
  const userId = query.get('userId') ? query.get('userId') : query.get('user_id');
  const productId = query.get('productId') ? query.get('productId') : query.get('product_id');
  const companyProcessId = query.get('companyProcessId') ? query.get('companyProcessId') : query.get('companyProcess_id');

  const params = {
    userId: userId ? userId : 'anonymous',
    productId: productId ? productId : 'non_set',
    companyProcessId: companyProcessId ? companyProcessId : 'non_set',
  }

  return params;
}
