import React from 'react';

export default function(){
  return (
      <div className="cmp-footer">
        <div className="container">
          <div className="logo-container">
            <img src='https://www.autofact.cl/img/logo-autofact-blanco.png' className="img-fluid" alt="Logo Autofact"/>
          </div>
        </div>
      </div>
  )
}
