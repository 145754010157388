const __envs = require('../../config.json')
export default {
  apiEndpoint: __envs.API_ENDPOINT,
  apiCompanyCustomerComunicationEndpoint: __envs.API_CUSTOMER_COMUNICATION,
  tagmanagerContainer: __envs.TAGMANAGER_CONTAINER,
  uuidExpirationDays: __envs.UUID_EXPIRATION_DAYS | 10,
  debounceTimeApiRequest: __envs.DEBOUNCE_TIME_API_REQUEST | 1500,
  StorageUUIDName: 'survey-uuid',
  StorageUUIDExpirationName: 'uuid-expiration-date',
  emailRegex: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
}
