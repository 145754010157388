export default {
  appStatus :{
    initial:'loading the survey',
    loading:'busy doing something',
    surveyIsLoaded:'survey is loaded',
    surveyAnswerIsSaved:'survey answer is saved',
    fail:'operations fails'
  },
  defaultValues:{
    title:'Queremos ser mejores, pero para eso necesitamos saber qué opinas',
    answerView:{
      app_title: 'Queremos ser mejores, pero para eso necesitamos saber qué opinas',
      title: 'Actualmente no tenemos encuestas',
      subtitle: '¡Gracias por visitarnos! Ahora mismo no tenemos encuestas vigentes',
      linkText: 'Ir a autofact.cl',
      link: 'https://autofact.cl'
    },
    extraErrors:{},
    survey:{
      id: -1,
      jsonSchema: {},
      uiSchema: {},
      extraErrors: {},
      formData: {}
    },
    defaults:{
      app_title: 'Queremos ser mejores, pero para eso necesitamos saber qué opinas',
      title: 'Actualmente no tenemos encuestas',
      subtitle: '¡Gracias por visitarnos! Ahora mismo no tenemos encuestas vigentes',
      linkText: 'Ir a autofact.cl',
      link: 'https://autofact.cl'
    },
    surveyErrorView:{
      app_title: 'Queremos ser mejores, pero para eso necesitamos saber qué opinas',
      title: 'Ha ocurrido un error',
      subtitle: 'Por favor inténtalo más tarde',
      linkText: 'Volver a intentar',
      link: ''
    }
  }
}
