import * as React from 'react';
import {observer} from 'mobx-react'

import SurveyStore from '../../../store/models/SurveyModel';

export default observer(function(){

  return (
      <div className="cmp-header">
        <div className="container">
          <div className="logo-container">
            <img data-src="https://www.autofact.cl/img/logo-autofact-blanco.png" className="img-fluid lozad"
                 alt="Logo Autofact"/>
          </div>
          <h1 className="main-title font-size-38 font-raleway font-bold">{SurveyStore.title}</h1>
        </div>
      </div>
  )
});