import React from 'react';
import './App.scss';


import { Helmet } from 'react-helmet';
import Footer from './components/containers/Footer/Footer';
import Header from './components/containers/Header/Header';
import NavBar from './components/containers/NavBar/NavBar';
import SurveyRouter from './components/routes/SurveyRouter';

require('./scripts/tagmanager');
var WebFont = require('webfontloader');
const lozad = require('lozad');
const observer = lozad();

class App extends React.Component{
  componentDidMount(): void {
    WebFont.load({
      google: {
        families: ['Raleway:400,700', 'Roboto:400,700']
      }
    });
    observer.observe();

  }

  render(){
    return (
        <div className="app">
          <Helmet>
            <title>Autofact | Encuestas</title>
            <meta name="description" content="Sistema de Encuestas para Autofact" />
          </Helmet>

          <NavBar></NavBar>
          <Header></Header>
          <div className="app-body">
            <div className="container">
              <div className="custom-container">
                <SurveyRouter/>
              </div>
            </div>
            <Footer></Footer>
          </div>
        </div>
    );
  }

}

export default App;
