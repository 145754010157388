import React from "react";
import {BrowserRouter as AppRouter, Switch, Route, Redirect} from 'react-router-dom';

import SurveyForm from '../containers/SurveyForm/SurveyForm';
import DefaultView from '../containers/SurveyAnswerView/SurveyAnswerView';

export default function () {
  return (
      <AppRouter>
        <Switch>
          <Route exact path="/:countryCode/:slug">
            <SurveyForm/>
          </Route>
          <Route>
            <DefaultView/>
          </Route>
          <Redirect to="/"/>
        </Switch>
      </AppRouter>
  )
}