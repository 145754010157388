import * as React from 'react';

export default function () {
  return (
      <div className="cmp-main-navbar">
        <div className="img-container">
          <img className="img-fluid" alt="" src="https://www.autofact.cl/img/logo-autofact-blanco.png"/>
        </div>
      </div>
  )

}
