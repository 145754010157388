import React, { useState } from 'react';
import { FieldProps } from 'react-jsonschema-form';
import dot from "dot-object";
import { Fragment } from 'react';
import SurveyStore from '../../store/models/SurveyModel';
/**
 * To use correctly this helper, you should have deffined a property called "Otro" in your Enum field.
 */

function SortRandomEnumValuesRadioButtonComponent(props: FieldProps) {
  // const values
  const OTHER_VALUE = 'Otro';
  const [formData, setFormData ] = useState(props.formData);
  const propNameOther = props.name + '_Otro';
  const [otherTextAreaValue, setOtherTextAreaValue] = useState(SurveyStore.survey.formData[propNameOther])
  const [sortOptionsValues, setSortOptionsValues ] = useState(true);
  const [sortedValues, setSortedValues ] = useState([]);
  const [otherDetail, setOtherDetail] = useState(false);

  // @ts-ignore
  const enumOptions = props.schema.enum;


  const onChange = (event?: any) => {
    // TODO: limpiar alernativas en dependencia cuando se cambia la alternativa elegida

    let value = dot.pick('target.value', event);

    props.onChange(value);

    setFormData(value);

    if(value === OTHER_VALUE){

      setOtherDetail(true);
    }else{
      setOtherDetail(false);
    }
  }

  const sortOptions = (options: any) => {

    let enumOptionsSort;

    // Sort values only one time
    if(sortOptionsValues){

      if(options.includes(OTHER_VALUE)){
        if(formData === OTHER_VALUE){
          setOtherDetail(true);
        }
        enumOptionsSort = [...options]?.slice().sort(() => Math.random() - 0.5)
        enumOptionsSort.push(enumOptionsSort.splice(enumOptionsSort.indexOf('Otro'),1)[0]);
      }else{
        enumOptionsSort = options?.slice().sort(() => Math.random() - 0.5)
      }

      setSortOptionsValues(false);
      setSortedValues(enumOptionsSort);
    }

    return enumOptionsSort || sortedValues;
  }

  const saveOtherDetail = (event: any) => {

    let value = dot.pick('target.value', event);
    const key: string = props.name + '_' + OTHER_VALUE;

    let obj: any = {}
    obj[key] = value;
    
    setOtherTextAreaValue(value);
    SurveyStore.setExtraData(obj);
    props.onChange(props.formData);
  }
  
  const optionSorted = sortOptions(enumOptions);

  return (
    <Fragment>
      <label className="control-label" htmlFor={props.idSchema.$id}>
        {props.schema.title}{ props.required ? '*' : null }
      </label>
      <div className="checkboxes" id={props.idSchema.$id}>
        { 
          // @ts-ignore
          optionSorted?.map((option, index) => 
            <div className="checkbox" key={index}>
              <label>
                <span>
                  <input type="radio" checked={option === formData}  name={props.name} key={index} id={props.idSchema.$id + '_' + index} value={option} onChange={onChange} /><span><label htmlFor={props.idSchema.$id + '_' + index }>{option}</label></span>
                </span>
              </label>
            </div>
          ) 
        }
      </div>

      {
        otherDetail &&
        <div className="form-group field field-string">
          <label className="control-label" htmlFor="otherDetailField">¿Qué otro?</label>
          <textarea  id="otherDetailField" value={otherTextAreaValue} name="otherDetailField" className="form-control" onChange={ saveOtherDetail } placeholder="Por favor, coméntamos más detalles sobre tu respuesta" required>
          </textarea>
        </div>
      }
    </Fragment>
  );
  
}

export default SortRandomEnumValuesRadioButtonComponent;