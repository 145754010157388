import React, { useState } from 'react';
import { FieldProps } from 'react-jsonschema-form';
import dot from "dot-object";
import { Fragment } from 'react';
import SurveyStore from '../../store/models/SurveyModel';
/**
 * To use correctly this helper, you should have deffined a property called "Otro" in your Enum field.
 */

function RandonOptionEnumFieldComponent(props: FieldProps){
  // const values
  const OTHER_VALUE = 'Otro';

  const [sortOptionsValues, setSortOptionsValues ] = useState(true);
  const [sortedValues, setSortedValues ] = useState([]);
  const [formData, setFormData ] = useState(props.formData);
  const propNameOther = props.name + '_Otro';
  const [otherTextAreaValue, setOtherTextAreaValue] = useState(SurveyStore.survey.formData[propNameOther])
  const [otherDetail, setOtherDetail] = useState(false);

  // @ts-ignore
  const enumOptions = props.schema.items.enum;

  const sortOptions = (options: any) => {

    let enumOptionsSort;

    // Sort values only one time
    if(sortOptionsValues){

      if(options.includes(OTHER_VALUE)){
        enumOptionsSort = [...options]?.slice().sort(() => Math.random() - 0.5)
        enumOptionsSort.push(enumOptionsSort.splice(enumOptionsSort.indexOf('Otro'),1)[0]);
      }else{
        enumOptionsSort = options?.slice().sort(() => Math.random() - 0.5)
      }

      setSortOptionsValues(false);
      setSortedValues(enumOptionsSort);
    }

    return enumOptionsSort || sortedValues;
  }

  const toggleOtherDetailInput = (array: Array<string>) => {
    array.includes(OTHER_VALUE) ? setOtherDetail(true) : setOtherDetail(false);
  }

  const onChange = (event?: any) => {
  
    let value = dot.pick('target.value', event);
    let formDataLocal = formData;

    // add element or remove if exists
    if(formData.includes(value)){
      const index = formData.indexOf(value);
      formDataLocal.splice(index, 1);

      // clear extra data if option "Otro" was unselected
      if(value === OTHER_VALUE){
        const extraData: any = SurveyStore.getExtraData();
        const key: string = props.name + '_' + OTHER_VALUE;

        // delete key from extraData 
        delete extraData[key];
      }

    }else{
      if(value){
        formDataLocal.push(value);
      }
    }
        
    setFormData(formDataLocal);
    props.onChange(formDataLocal);

    toggleOtherDetailInput(formData);
  }

  const saveOtherDetail = (event: any) => {
    let value = dot.pick('target.value', event);
    const key: string = props.name + '_' + OTHER_VALUE;
    
    let obj: any = {}
    obj[key] = value;

    setOtherTextAreaValue(value);
    SurveyStore.setExtraData(obj);
    props.onChange(props.formData);
  }
  
  const optionSorted = sortOptions(enumOptions);

  return (
    <Fragment>
      <label className="control-label" htmlFor={props.idSchema.$id}>
        {props.schema.title}{ props.required ? '*' : null }
      </label>
      <div className="checkboxes" id={props.idSchema.$id}>
        { 
          // @ts-ignore
          optionSorted?.map((option, index) => 
            <div className="checkbox" key={index}>
              <label>
                <span>
                  <input
                    type="checkbox"
                    key={index}
                    id={`${props.idSchema.$id}_${index}`}
                    value={option}
                    onChange={onChange}
                  />
                  <span>
                    <label htmlFor={`${props.idSchema.$id}_${index}`}>{option}</label>
                  </span>
                </span>
              </label>
            </div>
          ) 
        }
      </div>

      {
        otherDetail &&
        <div className="form-group field field-string">
          <label
            className="control-label"
            htmlFor={ `${props.idSchema.$id}_${OTHER_VALUE}` }
          >¿Qué otro?</label>
          <textarea
            id={ `${props.idSchema.$id}_${OTHER_VALUE}` }
            name={`${props.name}_${OTHER_VALUE}`}
            value={otherTextAreaValue}
            className="form-control"
            onChange={ saveOtherDetail }
            placeholder="Por favor, coméntamos más detalles sobre tu respuesta"
            required
          ></textarea>
        </div>
      }
    </Fragment>
  );
}

export default RandonOptionEnumFieldComponent;