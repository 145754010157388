import envs from '../constants/__envs';
import axios from 'axios';

import {
  ISurveyQuery,
  ISurveyServices,
  IgetUserSurveysQuery,
  ISaveSurveyObject,
  IAsocciateSurveyQuery
} from '../../interfaces/ISurvey';

const resources = {
  survey: 'survey',
  surveyAnswer: 'survey-answer',
  getUserSurveyAnswers: 'get',
  retakeSurveyAnswer: 'retake',
  updateSurveyAnswer: 'update',
  surveyCompany: 'survey-company',
  associate: 'associate-survey',
};

class Services implements ISurveyServices {

  async getSurvey(params: ISurveyQuery) {
    try {
      const survey = await axios.get(`${envs.apiEndpoint}${resources.survey}`, {
        params
      });
      return survey.data && survey.data.data ? survey.data.data : false;
    } catch (e) {
      return false;
    }
  }

  async getUserSurveyAnswers(params: IgetUserSurveysQuery) {
    try {
      const surveys = await axios.get(`${envs.apiEndpoint}${resources.surveyAnswer}/${resources.getUserSurveyAnswers}`, {params} );
      return surveys.data && surveys.data.data ? surveys.data.data : false;
    } catch (e) {
      return e;
    }
  }

  async saveSurveyAnswer(body: ISaveSurveyObject) {
    try {
      const survey = await axios.post(`${envs.apiEndpoint}${resources.surveyAnswer}`, {...body});
      return survey.data && survey.data.data ? survey.data.data : false;
    } catch (e) {
      return false;
    }
  }

  async updateSurveyAnswer(queryParams: any, body: object) {
    try {
      // await axios.put(`${envs.apiEndpoint}${resources.surveyAnswer}/${resources.updateSurvey}`, {...params});
      const survey = await axios.put(`${envs.apiEndpoint}${resources.surveyAnswer}/${resources.updateSurveyAnswer}`
      , {...body}, {params: {...queryParams}});
      return survey.data && survey.data.data ? survey.data.data : false;
    } catch (error) {
      return false;
    }
  }



  async associateSurvey (body: IAsocciateSurveyQuery) {
    try {
      const survey = await axios.post(`${envs.apiEndpoint}${resources.surveyCompany}/${resources.associate}`
          , {...body});
      return survey.data && survey.data.data ? survey.data.data : false;
    } catch (error) {
      return false;
    }
  }

}

export default new Services();
